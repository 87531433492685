import React, { Component } from 'react';
import { MDBSpinner } from 'mdb-react-ui-kit';
import MainContent from '../main-content/MainContent';
import HeaderContent from '../header/HeaderContent';
import ErrorPage from '../error-boundary/ErrorPage';
import { withOktaAuth } from '@okta/okta-react';
import clearTrackService from '../services/service';
import * as Constants from '../constants/constants';
import {
  BrowserRouter as Router, withRouter
} from "react-router-dom";
import IdleTimer from 'react-idle-timer'
import { UserContext } from '../contexts/UserContext';

export default withRouter(
  withOktaAuth(class Home extends Component {

    constructor(props) {
      super(props);
      if (this.props.authState.accessToken == undefined)
        this.props.oktaAuth.signOut('/');
      this.idleTimer = null
      this.onIdle = this._onIdle.bind(this);
      this.state = {
        isValidUser: true,
        isLoading: true,
        isLoggedIn: false,
        user_name: null,
        user_full_name: null,
        user_profile: null,
        user_profile_okta: null,
        errorId: 0,
        errorDesc: '',
        active_tenant: {
          tenant_id: null,
          tenant_name: null,
          division_role : []
        },
        active_route: '/',
        dashboardId: 1,
        dashboardName: "Show Seasons",
        sectionName: "showSeasons",
        sideNavOpen: false,
        reportTabData: [],
      }
    }

    updateSideNavOpen = (value) => {
      this.setState({ sideNavOpen: value })
    }

    _onIdle(e) {
      //authService.logout();
      this.props.oktaAuth.signOut('/');
      this.setState({
        isLoggedOut: true,
        errorId: '440',
        errorDesc: 'Session is timed out. Please login again!'
      });

    }


    async componentDidMount() {
      try {
        this._isMounted = true;
        await this.props.oktaAuth.getUser()
          .then(response => {
            let name = response.name
            let email = response.email;

            localStorage.setItem('user_email', email);
            localStorage.setItem('user_full_name', name);
            this.setState({ user_full_name: name });

            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/loggedInUser', 0)//"./user_data.json") 
              .then(response => {
                if (this._isMounted) {
                  if (response?.length > 0 || response?.data?.length > 0) {
                    let userProfile = response.data?.[0];
                    let userId = userProfile?.user_id;
                    localStorage.setItem('user_id', userId);
                    localStorage.setItem('user_profile', JSON.stringify(userProfile));
                    var is_active_user = userProfile?.is_active;
                    if (!is_active_user) {
                      this.setState({
                        isValidUser: false,
                        isLoggedIn: false,
                        isLoading: false,
                        errorId: '401',
                        errorDesc: 'User is marked as inactive in the system. Please contact admin for CLEARTRACK application'
                      });
                    }
                    else {
                      this.setState({
                        isValidUser: true,
                        isLoading: false,
                        isLoggedIn: true,
                        user_name: userProfile?.name,
                        user_profile: userProfile
                      });
                    }
                    this.processPermissions(userProfile);
                  }
                  else {
                    this.setState({
                      isValidUser: false,
                      isLoggedIn: false,
                      isLoading: false,
                      errorId: '401',
                      errorDesc: 'User is not available in the system or marked as inactive. Please contact admin for CLEARTRACK application'
                    });
                  }
                }
              },
                (err) => {
                  console.log('Service Error : Log In -> Getting User Profile ' + err);
                  this.setState({
                    isValidUser: false,
                    isLoggedIn: false,
                    isLoading: false,
                    errorId: '503',
                    errorDesc: 'User profile service is not available. Please try again and contact admin for CLEARTRACK application'
                  });
                });


          },
            (err) => {
              console.log(err);
              this.setState({
                isValidUser: false,
                isLoggedIn: false,
                isLoading: false,
                errorId: '503',
                errorDesc: 'OKTA user profile service is not available. Please try again and contact admin for CLEARTRACK application'
              });
            });
      }
      catch (err) { }
    }
    processPermissions(userProfile) {
      if (userProfile) {
        let tenants = [];
        var default_tenant_id = userProfile?.tenants[0]?.tenant_id || null;
        var active_tenant = localStorage.getItem("active_tenant_id");
        this.setState({ tenants: userProfile.tenants });
        userProfile.tenants.forEach((tenant) => {
          if (tenant.tenant_id == null) {
            tenant.tenant_id = 0;
            tenant.tenant_name = "All Tenants";
          }
          tenants.push({
            "tenant_id": tenant.tenant_id,
            "tenant_name": tenant.tenant_name
          });
          localStorage.setItem("tenants", tenants);

          if ((active_tenant == "null" || !active_tenant) && default_tenant_id == tenant.tenant_id) {
            localStorage.setItem("active_tenant_id", tenant.tenant_id);
            localStorage.setItem("active_tenant_name", tenant.tenant_name);
            active_tenant = tenant.tenant_id;
          }

          if (active_tenant == tenant.tenant_id) {
            this.processPermissionsforTenant(tenant);
          }
          //permissionObjs.push(permObj);
        });
      }
    }


    processPermissionsforTenant(tenant) {
      var default_home_page = tenant.default_home_page;
      var default_home_page_option_id = tenant.default_home_page_option_id
      var active_tenant = { ...this.state.active_tenant };
      let common_permissions =[];

      let division_role = tenant.division_role;
      // let is_user_provisioner = tenant.is_user_provisioner;
      active_tenant.tenant_id = tenant.tenant_id;
      active_tenant.tenant_name = tenant.tenant_name;
      active_tenant.division_role = division_role;
      // active_tenant.is_user_provisioner = is_user_provisioner;
      active_tenant.default_home_page = default_home_page;
      active_tenant.default_home_page_option_id = default_home_page_option_id;
      if(active_tenant.division_role?.length === 1) {
        active_tenant.division_role.map(div => {
          common_permissions = [ ...div.permissions ]
        })
      }
      else if(active_tenant.division_role?.length > 1) {
        let combined_permissions = []
        active_tenant.division_role.map((div) => {
          combined_permissions = [ ...combined_permissions, div.permissions]
        })
        let dummy_permissions = [];
        common_permissions = combined_permissions.reduce((accumulator, current) => {
          return accumulator.concat(current);
        })
        common_permissions = Object.values(common_permissions.reduce((accumulator, current) => {
          if (accumulator[current.permission_id]?.permission_id === current.permission_id) {
            accumulator[current.permission_id] = {
              permission_id: current.permission_id,
              permission_name: current.permission_name,
              permission_group_name: current.permission_group_name,
              edit: current.edit || accumulator[current.permission_id]?.edit,
              view: current.view || accumulator[current.permission_id]?.view,
              delete: current.delete || accumulator[current.permission_id]?.delete
            }
          }
          else {
            accumulator[current.permission_id] = current
          }
          return accumulator
        }, {}));
        console.log("common_permissions:",common_permissions);
        }
      // active_tenant.division_role.map((dr)=>{
      //   common_permissions = [...common_permissions,...dr.permissions]
      // })
      active_tenant.common_permissions = common_permissions;
     
      let dashboardName = this.state.dashboardName;
      let sectionName = this.state.sectionName;
      let dashboardId = this.state.dashboardId;
      dashboardName = default_home_page;
      sectionName = default_home_page === "Show Seasons" ? "showSeasons" :  default_home_page === "Clearance Records" ? "clearanceRecords" :  default_home_page === "Songs" ? "songLibrary" :  default_home_page === "Licensors" ? "licensors" :  default_home_page === "Payees" ? "payees" :  default_home_page === "Cue Sheets" ? "cueSheets" :  default_home_page === "Music Supervisors/Show Composers" ? "musicEditor" :  default_home_page === "TV Cr. Mus. Bdgt" ? "" :  default_home_page === "Reports" ? "reports": default_home_page === "People (SC's, MS's, ME's)" ? "people" : default_home_page === "Budgets" ? "budget" : default_home_page === "Rights" ? "rights" : default_home_page === "Can see License Tracking tab" ? "licenseTracking" : null;
      dashboardId = default_home_page === "Show Seasons" ? 1 :  default_home_page === "Clearance Records" ? 2 :  default_home_page === "Songs" ? 7 :  default_home_page === "Licensors" ? 8 :  default_home_page === "Payees" ? 9 :  default_home_page === "Cue Sheets" ? 5 :  default_home_page === "Music Supervisors/Show Composers" ? 10 :  default_home_page === "TV Cr. Mus. Bdgt" ? 16 :  default_home_page === "Reports" ? 13 : default_home_page === "Budgets" ? 6 : default_home_page === "Rights" ? 4 : default_home_page === "People (SC's, MS's, ME's)" ? 11 : default_home_page === "Can see License Tracking tab" ? 3 : null;
      this.setState({
        active_tenant: active_tenant,
        dashboardName,
        sectionName,
        dashboardId
      });
      localStorage.setItem("active_tenant_id", tenant.tenant_id);
      localStorage.setItem("active_tenant_name", tenant.tenant_name);
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    handleSideNavActiveMain = (id, name, sectionName, reportTabData=[]) => {
      this.setState({ dashboardId: id, dashboardName: name, sectionName: sectionName,reportTabData:reportTabData})
    }

    switchTenant = (tenant_id) => {
      var tenant = this.state.user_profile.tenants.find(tenant => tenant.tenant_id == tenant_id);
      this.processPermissionsforTenant(tenant);
      this.props.history.push('/', this.state);
      window.location.reload();
    }

    render() {
      return (
        (this.state.isLoading ?

          <div className='alignCenter '>
            <div className="align-center-vertical">
              <MDBSpinner role='status' color="primary">
              </MDBSpinner>
              <div>
                <span style={{ textAlign: 'center' }}>Loading CLEARTRACK....</span>
              </div>
            </div>
          </div>

          : (
            !this.state.isValidUser ?
              <ErrorPage isLoggedIn={this.state.isValidUser} errorId={this.state.errorId}
                errorDesc={this.state.errorDesc} ></ErrorPage>
              :

              <>
                {/* <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onIdle={this.onIdle}
                  debounce={250}
                  timeout={1000 * 60 * Constants.IDLE_TIMEOUT} /> */}
                <UserContext.Provider value={{
                  user_profile: this.state.user_profile,
                  active_tenant: this.state.active_tenant,
                  switchTenant: this.switchTenant.bind(this),
                  handleSideNavActiveMain: this.handleSideNavActiveMain.bind(this),
                  dashboardId: this.state.dashboardId,
                  dashboardName: this.state.dashboardName,
                  sectionName: this.state.sectionName,
                  sideNavOpen: this.state.sideNavOpen,
                  reportTabData:this.state.reportTabData,
                  updateSideNavOpen: this.updateSideNavOpen.bind(this),
                }}>
                  <Router>
                    <div className="h-100">
                      <HeaderContent {...this.state} {...this.props}
                        authState={this.props.authState} switchTenant={this.switchTenant.bind(this)} />
                      {/* {this.props.location.pathname === '/' || this.props.location.pathname === '/callback' ? <DashboardContainer {...this.props} sectionName={this.state.sectionName} /> : */}
                      <MainContent {...this.state} {...this.props}
                        {...this.functions}
                        wrappedComponentRef={this.mainContent}
                        switchTenant={this.switchTenant.bind(this)} />
                      {/* //} */}
                    </div>
                  </Router>
                </UserContext.Provider>
              </>
          )
        )
      );
    }
  }
  )
);
