import React, { Component } from "react";
import { MDBCol, MDBRow, MDBCard, MDBCardBody, MDBCardText, MDBIcon } from 'mdbreact';
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import TextField from '@material-ui/core/TextField';
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./AdHocReport.scss";
import { valueList, labelList, initialAdhocDetails, AdHocTabList, existingAdHocDropDownReport, LicenseTypeOptions, ShowYearOptions, existingAdHocReport,BatchOptions } from "../Reports/config";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateField from "../../../components/SharedComponents/DateField/DateField";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import { validateNumbersOnly, getFormattedDate } from "../../../Common/Helper";


class AdHocReport extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      config: valueList,
      iconfig: labelList,
      adhocDetails: initialAdhocDetails,
      seasonOptions: [],
      affiliationOptions: [],
      pctOptions: [],
      episodeOptions: [],
      programOptions: [],
      episodeTitleOptions: [],
      showYearOptions: [],
      episodeRangeOptions: [],
      // productEntityOptions: [],
      cleranceOptions: [],
      initialRightOptions: [],
      optionRights: [],
      licenseOption: [],
      termOptions: [],
      territoryOptions: [],
      statusOpions: [],
      tabList: [...AdHocTabList],
      licensorTypeOptions: [...LicenseTypeOptions],
      backUpLicensorTypeOptions: [...LicenseTypeOptions],
      showYearOptions: [...ShowYearOptions],
      backUpShowYearOptions: [...ShowYearOptions],
      filterValues: [],
      filterShowIdValues: [],
      filterEntityValues: [],
      filterShowYearValues: [],
      filterWriterIdValues: [],
      filterPerformerValues: [],
      filterLicensorTypeValues: [],
      filterInitialRightsValues: [],
      filterOptionalRightValues: [],
      filterTerritoryValues: [],
      filterTermValues: [],
      filterStatusValues: [],
      filterShowValues: [],
      filterSeasonValues: [],
      filterEpisodeValues: [],
      filterClearanceRepValues: [],
      filterCompositionValues: [],
      filterLicensorValues: [],
      filterUseTimingValues: [],
      filterEpisodeRangeValues: [],
      filterPerformerValues: [],
      filterShowSeasonsIds: [],
      filterEpTitleValues: [],
      filterAirDateValues: [],
      filterFeeRangeValues: [],
      filteredShowSeasonIds: [],
      selectedFields: [],
      selectedGroupFields: [],
      existingAdHocReport: [...existingAdHocReport],
      sortFieldOptions: [...existingAdHocDropDownReport],
      groupByOptions: [...existingAdHocDropDownReport],
      selectedType: {},
      selectedGroupType: {},
      batchOptions:[...BatchOptions]
      // filterList:{
      //   productEntityOptions: [],
      // }
    };
  }

  componentDidMount() {
    //this.fetchSeasonsData();
    this.getEntitySearchData("RIGHTS")
    this.getEntitySearchData("TERM")
    this.getEntitySearchData("LICENSOR")
    this.getEntitySearchData("WRITER")
    this.getEntitySearchData("PRODUCTION_ENTITY")
    this.getEntitySearchData("PERFORMER")
    this.getEntitySearchData("TERRITORY")
    this.getEntitySearchData("USER")
    this.getEntitySearchData("SHOW")
    this.getEntitySearchData("SEASON")
    this.fetchStaticData("lu_status")
    this.setInitialDetails()
    // this.getEntitySearchData("WRITER")
    // this.getEntitySearchData("WRITER")

    // this.fetchEntityList('PRODUCTION_ENTITY', filterConfig.production_entity, 'initialRightOptions')
  }

  setInitialDetails = () => {
    this.setState({ selectedId: 1 })
    this.setState((prevState) => ({
      adhocDetails: {
        ...prevState.adhocDetails,
        ['progSeasonEpisode_Equals']: "EQUAL",
        ['prodEntity_Equals']: "EQUAL",
        ['showYear_Equals']: "EQUAL",
        ['performer_Equals']: "EQUAL",
        ['licensorType_Equals']: "EQUAL",
        ['initRights_Equals']: "EQUAL",
        ['optRights_Equals']: "EQUAL",
        ['territory_Equals']: "EQUAL",
        ['term_Equals']: "EQUAL",
        ['status_Equals']: "EQUAL",
        ['clearanceRep_Equals']: "EQUAL",
        ['composition_Equals']: "LIKE",
        ['licensor_Equals']: "LIKE",
        ['useTiming_Equals']: "LIKE",
        ['writer_Equals']: "EQUAL"
      },
    }))
  }

  fetchStaticData = (entity) => {
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
        `/staticData?staticTable=${entity}`,
        this.props.userContext?.active_tenant?.tenant_id
      )
      .then(
        (response) => {
          if (entity === "lu_status") {
            let formattedList = response.data?.map((item) => ({
              value: item.id,
              label: item.name,
              is_active: item.is_active,
            }));
            // formattedList.unshift({ label: "Select", value: null })
            this.setState({ statusOptions: formattedList, backUpStatusOptions: formattedList });
          }
        },
        (err) => {
          console.log("Error in fetching static data:", err);
        }
      );
  }

  getEntitySearchData = (entityType) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entityType}&searchString=null`, this.props?.userContext?.active_tenant?.tenant_id, null)
      .then(response => {
        if (entityType === "RIGHTS") {
          let initialRights = response.data?.filter(item => item.is_initial_right === 1)
          let optionalRights = response.data?.filter(item => item.is_optional === 1)
          let formattedListInitial = initialRights?.map(item => ({ value: item.right_id, label: item.right_abbr }));
          let formattedListOptional = optionalRights?.map(item => ({ value: item.right_id, label: item.right_abbr }));
          this.setState({ initialRightOptions: formattedListInitial, backUpInitialRightOptions: formattedListInitial, optionalRightOptions: formattedListOptional, backUpOptionalRightOptions: formattedListOptional });
        } else if (entityType === "TERM") {
          let formattedList = response.data?.map(item => ({ value: item.term_id, label: item.term_name }));
          this.setState({ termOptions: formattedList })
        } else if (entityType === "LICENSOR") {
          let formattedList = response.data?.map(item => ({ value: item.licensor_id, text: item.licensor_name }));
          this.setState({ licensorOptions: formattedList })
        } else if (entityType === "WRITER") {
          let formattedList = response.data?.map(item => ({ value: item.writer_id, label: item.writer_name }));
          this.setState({ writerOptions: formattedList, backUpWriterOptions: formattedList })
        } else if (entityType === "PRODUCTION_ENTITY") {
          let formattedList = response.data?.map(item => ({ value: item.production_entity_id, label: item.company }));
          this.setState({ productionEntityOptions: formattedList, backUpProductionEntityOptions: formattedList })
        } else if (entityType === "PERFORMER") {
          let formattedList = response.data?.map(item => ({ value: item.performer_id, text: item.performer_name }));
          this.setState({ performerOptions: formattedList, backUpPerformerOptions: formattedList })
        } else if (entityType === "TERRITORY") {
          let formattedList = response.data?.map(item => ({ value: item.territory_id, label: item.territory_abbr }));
          this.setState({ territoryOptions: formattedList, backUpTerritoryOptions: formattedList })
        } else if (entityType === "USER") {
          let formattedList = response.data?.map(item => ({ value: item.user_id, label: item.full_name }));
          this.setState({ clearanceRepOptions: formattedList, backUpClearanceRepOptions: formattedList })
        } else if (entityType === "SHOW") {
          let formattedList = response.data?.map(item => ({ value: item.show_id, text: item.show_name, show_season_ids: item.show_season_id }));
          this.setState({ showOptions: formattedList, backUpShowOptions: this.state.backUpShowOptions?.length > 0 ? [...this.state.backUpShowOptions, ...formattedList] : formattedList })
        } else if (entityType === "SEASON") {
          let formattedList = response.data?.map(item => ({ value: item.season_id, label: item.season_name }));
          this.setState({ seasonOptions: formattedList, backUpSeasonOptions: formattedList, episodeOptions: [], backUpEpisodeOptions: [] })
          // } else if (entityType === "EPISODES") {
          //   let formattedList = response.data?.map(item => ({ value: item.show_season_episode_id, label: `${item?.episode_number ? item?.episode_number : ''}${item?.episode_title ? -item?.episode_title : ''}`}));
          //   this.setState({ episodeOptions: formattedList, backUpEpisodeOptions: formattedList })
        }
      },
        (err) => {
          console.log("Error in fetching entity list:", err)
        })
  }

  handleChangeDropDown = (field, val) => {
    this.setState({
      [field]: { value: val?.target.value, label: val?.currentTarget.innerText, icon: "check-circle" }
    })
  }



  handleChange = (field, value, data) => {
    this.setState((prevState) => ({
      adhocDetails: {
        ...prevState.adhocDetails,
        [field]: value,
      },
    }), () => {
      if (field === "performerList" && !this.state.filterPerformerValues?.find(item => item === value[0]?.value)) {
        let filterValues = this.state.filterValues
        let valuePopped = value[0]
        filterValues.push("Performer " + (this.state.adhocDetails?.performer_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + valuePopped?.text)
        let filterPerformerValues = this.state.filterPerformerValues
        filterPerformerValues.push(valuePopped?.value)
        // let performerOptions = this.state.performerOptions
        // let filteredPerformerOptions = performerOptions.filter(item => item.value !== valuePopped?.value)
        this.setState({ filterPerformerValues, filterValues })
      } else if (field === "showList" && !this.state.filterShowValues?.find(item => item === value[0]?.value)) {
        let filterValues = this.state.filterValues
        let valuePopped = value[0]
        filterValues.push("Show " + (this.state.adhocDetails?.prodEntity_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + valuePopped?.text)
        let filterShowValues = this.state.filterShowValues
        filterShowValues.push(valuePopped?.value)
        let filterShowSeasonsIds = this.state.filterShowSeasonsIds
        filterShowSeasonsIds.push({ showId: valuePopped?.value, showSeasonId: valuePopped?.show_season_ids })
        let trimmed = filterShowSeasonsIds.map(item => item.showSeasonId).join()
        this.setState({ filterShowValues, filterValues, filterShowSeasonsIds }, () => {
          this.filterSeasons(filterShowValues.join())
        })
      }
    })
  }


  handleClearFilters = () => {
    let { adhocDetails, filterValues, selectedFields, selectedGroupFields, filterEntityValues, filterShowValues, filterSeasonValues,
      filterEpisodeValues, filterEpisodeRangeValues, filterShowYearValues, filterCompositionValues, filterPerformerValues,
      filterLicensorValues, filterLicensorTypeValues, filterInitialRightsValues, filterOptionalRightValues, filterTerritoryValues,
      filterTermValues, filterUseTimingValues, filterStatusValues, filterClearanceRepValues } = this.state;
    filterValues = [],
      filterEntityValues = [],
      filterShowValues = [],
      filterSeasonValues = [],
      filterEpisodeValues = [],
      filterEpisodeRangeValues = [],
      filterShowYearValues = [],
      filterCompositionValues = [],
      filterPerformerValues = [],
      filterLicensorValues = [],
      filterLicensorTypeValues = [],
      filterInitialRightsValues = [],
      filterOptionalRightValues = [],
      filterTerritoryValues = [],
      filterTermValues = [],
      filterUseTimingValues = [],
      filterStatusValues = [],
      filterClearanceRepValues = []
    this.setState({
      filterValues, filterEntityValues, filterShowValues, filterSeasonValues, filterEpisodeValues, filterEpisodeRangeValues, filterShowYearValues, filterCompositionValues, filterPerformerValues,
      filterLicensorValues, filterLicensorTypeValues, filterInitialRightsValues, filterOptionalRightValues, filterTerritoryValues,
      filterTermValues, filterUseTimingValues, filterStatusValues, filterClearanceRepValues, adhocDetails: { ...initialAdhocDetails },
      sortFieldOptions: [...existingAdHocDropDownReport],
      groupByOptions: [...existingAdHocDropDownReport],
      selectedFields: [], selectedGroupFields: []
    }, () => {
      this.setInitialDetails()
    })
  }

  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode }, () => {
      let splitVal = this.state.removedItem.split(":");
      let name = splitVal[0].trim();
      let valueId = splitVal[1].trim();
      let splitName = name.split(" ");
      if (splitName[0] === "Production") {
        let valueToBeRemoved = this.state.backUpProductionEntityOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpProductionEntityOptions.find(item => item.label === valueId)
        let filterEntityValues = [...this.state.filterEntityValues];
        let index = filterEntityValues.indexOf(valueToBeRemoved)
        filterEntityValues.splice(index, 1)
        let productionEntityOptions = [...this.state.productionEntityOptions]
        productionEntityOptions.push(valueToBeAdded)
        // productionEntityOptions.sort((a, b) => a.value - b.value)
        this.setState({ filterEntityValues, productionEntityOptions })
      } else if (splitName[0] === "Show" && splitName[1] === "Year") {
        let valueToBeRemoved = this.state.backUpShowYearOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpShowYearOptions.find(item => item.label === valueId)
        let filterShowYearValues = [...this.state.filterShowYearValues];
        let index = filterShowYearValues.indexOf(valueToBeRemoved)
        filterShowYearValues.splice(index, 1)
        let showYearOptions = [...this.state.showYearOptions]
        showYearOptions.push(valueToBeAdded)
        // productionEntityOptions.sort((a, b) => a.value - b.value)
        this.setState({ filterShowYearValues, showYearOptions })
      } else if (splitName[0] === "Performer") {
        let valueToBeRemoved = this.state.backUpPerformerOptions.find(item => item.text === valueId)?.value
        let valueToBeAdded = this.state.backUpPerformerOptions.find(item => item.text === valueId)
        let filterPerformerValues = [...this.state.filterPerformerValues];
        let index = filterPerformerValues.indexOf(valueToBeRemoved)
        filterPerformerValues.splice(index, 1)
        // let performerOptions = [...this.state.performerOptions]
        // performerOptions.push(valueToBeAdded)
        // productionEntityOptions.sort((a, b) => a.value - b.value)
        this.setState({ filterPerformerValues })
      } else if (splitName[0] === "Licensor" && splitName[1] === "Type") {
        let valueToBeRemoved = this.state.backUpLicensorTypeOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpLicensorTypeOptions.find(item => item.label === valueId)
        let filterLicensorTypeValues = [...this.state.filterLicensorTypeValues];
        let index = filterLicensorTypeValues.indexOf(valueToBeRemoved)
        filterLicensorTypeValues.splice(index, 1)
        let licensorTypeOptions = [...this.state.licensorTypeOptions]
        licensorTypeOptions.push(valueToBeAdded)
        this.setState({ filterLicensorTypeValues, licensorTypeOptions })
      } else if (splitName[0] === "Initial") {
        let valueToBeRemoved = this.state.backUpInitialRightOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpInitialRightOptions.find(item => item.label === valueId)
        let filterInitialRightsValues = [...this.state.filterInitialRightsValues];
        let index = filterInitialRightsValues.indexOf(valueToBeRemoved)
        filterInitialRightsValues.splice(index, 1)
        let initialRightOptions = [...this.state.initialRightOptions]
        initialRightOptions.push(valueToBeAdded)
        this.setState({ filterInitialRightsValues, initialRightOptions })
      } else if (splitName[0] === "Optional") {
        let valueToBeRemoved = this.state.backUpOptionalRightOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpOptionalRightOptions.find(item => item.label === valueId)
        let filterOptionalRightValues = [...this.state.filterOptionalRightValues];
        let index = filterOptionalRightValues.indexOf(valueToBeRemoved)
        filterOptionalRightValues.splice(index, 1)
        let optionalRightOptions = [...this.state.optionalRightOptions]
        optionalRightOptions.push(valueToBeAdded)
        this.setState({ filterOptionalRightValues, optionalRightOptions })
      } else if (splitName[0] === "Territory") {
        let valueToBeRemoved = this.state.backUpTerritoryOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpTerritoryOptions.find(item => item.label === valueId)
        let filterTerritoryValues = [...this.state.filterTerritoryValues];
        let index = filterTerritoryValues.indexOf(valueToBeRemoved)
        filterTerritoryValues.splice(index, 1)
        let territoryOptions = [...this.state.territoryOptions]
        territoryOptions.push(valueToBeAdded)
        this.setState({ filterTerritoryValues, territoryOptions })
      } else if (splitName[0] === "Status") {
        let valueToBeRemoved = this.state.backUpStatusOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpStatusOptions.find(item => item.label === valueId)
        let filterStatusValues = [...this.state.filterStatusValues];
        let index = filterStatusValues.indexOf(valueToBeRemoved)
        filterStatusValues.splice(index, 1)
        let statusOptions = [...this.state.statusOptions]
        statusOptions.push(valueToBeAdded)
        this.setState({ filterStatusValues, statusOptions })
      } else if (splitName[0] === "Clearance") {
        let valueToBeRemoved = this.state.backUpClearanceRepOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpClearanceRepOptions.find(item => item.label === valueId)
        let filterClearanceRepValues = [...this.state.filterClearanceRepValues];
        let index = filterClearanceRepValues.indexOf(valueToBeRemoved)
        filterClearanceRepValues.splice(index, 1)
        let clearanceRepOptions = [...this.state.clearanceRepOptions]
        clearanceRepOptions.push(valueToBeAdded)
        this.setState({ filterClearanceRepValues, clearanceRepOptions })
      } else if (splitName[0] === "Writer") {
        let valueToBeRemoved = this.state.backUpWriterOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpWriterOptions.find(item => item.label === valueId)
        let filterWriterIdValues = [...this.state.filterWriterIdValues];
        let index = filterWriterIdValues.indexOf(valueToBeRemoved)
        filterWriterIdValues.splice(index, 1)
        let writerOptions = [...this.state.writerOptions]
        writerOptions.push(valueToBeAdded)
        this.setState({ filterWriterIdValues, writerOptions })
      } else if (splitName[0] === "Show" && splitName[1] !== "Year") {
        let valueToBeRemoved = this.state.backUpShowOptions.find(item => item.text === valueId)?.value
        let valueToBeAdded = this.state.backUpShowOptions.find(item => item.text === valueId)
        let filterShowValues = [...this.state.filterShowValues];
        let index = filterShowValues.indexOf(valueToBeRemoved)
        filterShowValues.splice(index, 1)
        // let showOptions = [...this.state.showOptions]
        // showOptions.push(valueToBeAdded)
        //let showSeasonsToBeRemoved = this.state.backUpShowOptions.find(item => item.text === valueId)?.show_season_ids
        let filterShowSeasonsIds = [...this.state.filterShowSeasonsIds]
        let allShowSeasonsOfRemovedItem = filterShowSeasonsIds?.find(item => item?.showId === valueToBeRemoved)?.showSeasonId?.split(',')
        let updatedFilterShowSeasonsIds = filterShowSeasonsIds?.filter(item => item?.showId != valueToBeRemoved)
        let updatedFilteredShowSeasonsIds = this.state.filteredShowSeasonIds?.filter(item => allShowSeasonsOfRemovedItem?.includes(item?.showSeasonId))
        // filterShowSeasonsIds.indexOf(showSeasonsToBeRemoved)
        // filterShowSeasonsIds.splice(index, 1)
        this.setState({ filterShowValues, filterShowSeasonsIds: updatedFilterShowSeasonsIds, filteredShowSeasonIds: updatedFilteredShowSeasonsIds }, () => {
          if (filterShowValues?.length > 0) {
            this.filterSeasons(filterShowValues.join())
          } else {
            this.getEntitySearchData("SEASON")
          }
          //this.filterEpisodes(filterShowSeasonsIds.join())
        })
      } else if (splitName[0] === "Season") {
        let valueToBeRemoved = this.state.backUpSeasonOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpSeasonOptions.find(item => item.label === valueId)
        let filterSeasonValues = [...this.state.filterSeasonValues];
        let index = filterSeasonValues.indexOf(valueToBeRemoved)
        filterSeasonValues.splice(index, 1)
        let filteredShowSeasonIds = [...this.state.filteredShowSeasonIds]
        let toBeRemovedIndex = filteredShowSeasonIds.findIndex(item => item.seasonId === valueToBeRemoved)
        filteredShowSeasonIds.splice(toBeRemovedIndex, 1)
        // let seasonOptions = [...this.state.seasonOptions]
        // seasonOptions.push(valueToBeAdded)
        this.setState({ filterSeasonValues, filteredShowSeasonIds }, () => {
          //let fetchedShowSeasonIds = filteredShowSeasonIds.map(item => item.showSeasonId)
          //this.filterEpisodes(fetchedShowSeasonIds.join())
          this.getEpisodes()
        })
      } else if (splitName[0] === "Episode" && (splitName[1] !== "Range" && splitName[1] !== "Title")) {
        let valueToBeRemoved = this.state.backUpEpisodeOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpEpisodeOptions.find(item => item.label === valueId)
        let filterEpisodeValues = [...this.state.filterEpisodeValues];
        let index = filterEpisodeValues.indexOf(valueToBeRemoved)
        filterEpisodeValues.splice(index, 1)
        // let episodeOptions = [...this.state.episodeOptions]
        // episodeOptions.push(valueToBeAdded)
        this.setState({ filterEpisodeValues })
      } else if (splitName[0] === "Composition") {
        let filterCompositionValues = this.state.filterCompositionValues
        let filteredCompositionValues = filterCompositionValues.filter(item => item !== valueId)
        this.setState({ filterCompositionValues: filteredCompositionValues })
      } else if (splitName[0] === "Licensor") {
        let filterLicensorValues = this.state.filterLicensorValues
        let filteredLicensorValues = filterLicensorValues.filter(item => item !== valueId)
        this.setState({ filterLicensorValues: filteredLicensorValues })
      } else if (splitName[0] === "Use") {
        let filterUseTimingValues = this.state.filterUseTimingValues
        let filteredUseTimingValues = filterUseTimingValues.filter(item => item !== valueId)
        this.setState({ filterUseTimingValues: filteredUseTimingValues })
      } else if (splitName[0] === "Episode" && splitName[1] === "Range") {
        // let splitValue = valueId.split("-");
        // let ep_from = splitValue[0].trim();
        // let ep_to = splitValue[1].trim();
        let filterEpisodeRangeValues = this.state.filterEpisodeRangeValues
        let filteredEpRangeValues = filterEpisodeRangeValues.filter(item => item !== valueId)
        this.setState({ filterEpisodeRangeValues: filteredEpRangeValues })
      } else if (splitName[0] === "Performer") {
        let valueToBeRemoved = this.state.backUpPerformerOptions.find(item => item.label === valueId)?.value
        let valueToBeAdded = this.state.backUpPerformerOptions.find(item => item.label === valueId)
        let filterPerformerValues = [...this.state.filterPerformerValues];
        let index = filterPerformerValues.indexOf(valueToBeRemoved)
        filterPerformerValues.splice(index, 1)
        let performerOptions = [...this.state.performerOptions]
        performerOptions.push(valueToBeAdded)
        this.setState({ filterPerformerValues, performerOptions })
      } else if (splitName[0] === "Episode" && splitName[1] === "Title") {
        let filterEpTitleValues = this.state.filterEpTitleValues
        let filteredEpTitleValues = filterEpTitleValues.filter(item => item !== valueId)
        this.setState({ filterEpTitleValues: filteredEpTitleValues })
      } else if (splitName[0] === "Air" && splitName[1] === "Date") {
        // let splitValue = valueId.split("-");
        // let ep_from = splitValue[0].trim();
        // let ep_to = splitValue[1].trim();
        let filterAirDateValues = this.state.filterAirDateValues
        let filteredAirDateValues = filterAirDateValues.filter(item => item !== valueId)
        this.setState({ filterAirDateValues: filteredAirDateValues })
      }
      else if (splitName[0] === "Fee") {
        let filterFeeRangeValues = this.state.filterFeeRangeValues
        let filteredFeeRangeValues = filterFeeRangeValues.filter(item => item !== valueId)
        this.setState({ filterFeeRangeValues: filteredFeeRangeValues })
      }
    });
  }

  filterSeasons = (value) => {
    // let showIds = value?.map(item => item.value)
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entityHierarchical?entity=SHOW-SEASON&entity_id="` + value + `"`,
      this.props?.userContext?.active_tenant?.tenant_id, null)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.season_id, label: item.season_name, show_season_id: item.show_season_id }));
        let uniqueArr = formattedList.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.value === value.value
          ))
        )
        this.setState({ seasonOptions: uniqueArr, backUpSeasonOptions: formattedList }, () => {
          //if(this.state.filteredShowSeasonIds.length > 0) {
          this.getEpisodes()
          //}
        })
      })
  }

  getEpisodes = () => {
    let filterShowSeasonsIds = this.state.filterShowSeasonsIds // [{showId: 1, showSeasonId: '1,2,3'}{showId: 2, showSeasonId: '4,5,6'}]
    let filteredShowSeasonIds = this.state.filteredShowSeasonIds // [{seasonId: 1, showSeasonId:  1}{seasonId: 1, showSeasonId: 4}]
    if (filteredShowSeasonIds?.length > 0) {
      this.filterEpisodes(filteredShowSeasonIds?.map(item => item?.showSeasonId))
    } else if (filterShowSeasonsIds.length > 0) {
      this.filterEpisodes(filterShowSeasonsIds?.map(item => item?.showSeasonId))
    } else {
      this.setState({ episodeOptions: [], backUpEpisodeOptions: [] })
    }
  }

  filterEpisodes = (value) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entityHierarchical?entity=SHOW-SEASON-EPISODES&entity_id="${value}"`,
      this.props?.userContext?.active_tenant?.tenant_id, null)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.show_season_episode_id, label: `${item?.episode_number ? item?.episode_number : ''}${item?.episode_title ? '-' + item?.episode_title : ''}` }));
        this.setState({ episodeOptions: formattedList, backUpEpisodeOptions: formattedList })
      })
  }

  onChangeCallBack = (field, val, eventValue, data) => {
    let newValue = val.filter(
      (value, index, arr) =>
        index === arr.findIndex((item) => item.value === value.value)
    );
    let modifiedValue = newValue?.map((item) => ({
      value: item.value,
      text: item.text,
      show_season_ids: item?.show_season_ids
    }));
    // let filterValues = this.state.filterValues;
    // let filterShowIdValues = this.state.filterShowIdValues;
    if (field === "initialRights") {
      this.handleChange("initialRights", modifiedValue);
    } else if (field === "optionalRights") {
      this.handleChange("optionalRights", modifiedValue);
    } else if (field === "termList") {
      this.handleChange("termList", modifiedValue);
    } else if (field === "licensorList") {
      this.handleChange("licensorList", modifiedValue);
    } else if (field === "writerList") {
      this.handleChange("writerList", modifiedValue);
    } else if (field === "productionEntityList") {
      this.handleChange("productionEntityList", modifiedValue);
    } else if (field === "performerList") {
      this.handleChange("performerList", modifiedValue);
    } else if (field === "territoryList") {
      this.handleChange("territoryList", modifiedValue);
    } else if (field === "licensorTypeList") {
      this.handleChange("licensorTypeList", modifiedValue);
    } else if (field === "showYearList") {
      this.handleChange("showYearList", modifiedValue);
    } else if (field === "clearanceRepList") {
      this.handleChange("clearanceRepList", modifiedValue);
    } else if (field === "statusList") {
      this.handleChange("statusList", modifiedValue);
    } else if (field === "showList") {
      this.handleChange("showList", modifiedValue);
    } else if (field === "seasonList") {
      this.handleChange("seasonList", modifiedValue);
    } else if (field === "episodeList") {
      this.handleChange("episodeList", modifiedValue);
    }
  }

  a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`
    };
  }

  fetchEntityList = (entity, config, node) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props?.userContext?.active_tenant?.tenant_id, null)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item[config.id], text: item[config.name] }));
        formattedList.unshift({ label: "Select", value: null })
        this.updateListState(formattedList, node, 'filterList')
      },
        (err) => {
          console.log("Error in fetching entity list:", err)
        })
  }

  fetchSeasonsData = () => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SEASON&searchString=null`,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.season_id, text: item.season_name }));
        this.setState({ seasonOptions: formattedList });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err);
          this.setState({ isFetchingClearanceDetails: false });
        })
  }

  getproductionEntityLists = () => {
    this.setState({ isLoading: true });
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=PRODUCTION_ENTITY&searchString=`, this.props.userContext?.active_tenant?.tenant_id, this.props?.initialRecordData?.division_id)
      .then((res) => {
        let formattedList = res?.data?.map(item => ({ value: item.production_entity_id, text: item.company }));
        this.setState({ productEntityOptions: formattedList, isLoading: false });

      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  sortingGroupByList = (index, value) => {
    let selectedGroupFields = [...this.state.selectedGroupFields]
    if (value === 'down') {
      let obj = selectedGroupFields[index + 1];
      selectedGroupFields[index + 1] = selectedGroupFields[index];
      selectedGroupFields[index] = obj
      this.setState({ selectedGroupFields })
    } else if (value === 'up') {
      let obj = selectedGroupFields[index - 1];
      selectedGroupFields[index - 1] = selectedGroupFields[index];
      selectedGroupFields[index] = obj
      this.setState({ selectedGroupFields })
    }
  }

  removeSortFields = (index, value) => {
    if (value === 'sort') {
      let selectedFields = [...this.state.selectedFields]
      let removed = selectedFields.splice(index, 1)
      let sortFieldOptions = [...this.state.sortFieldOptions]
      sortFieldOptions.push(removed[0])
      this.setState({ selectedFields, sortFieldOptions })
    } else if (value === 'group') {
      let selectedGroupFields = [...this.state.selectedGroupFields]
      let removed = selectedGroupFields.splice(index, 1)
      let groupByOptions = [...this.state.groupByOptions]
      groupByOptions.push(removed[0])
      this.setState({ selectedGroupFields, groupByOptions })
    }
  }

  sortingGroupList = (index, value) => {
    let selectedFields = [...this.state.selectedFields]
    if (value === 'down') {
      let obj = selectedFields[index + 1];
      selectedFields[index + 1] = selectedFields[index];
      selectedFields[index] = obj
      this.setState({ selectedFields })
    } else if (value === 'up') {
      let obj = selectedFields[index - 1];
      selectedFields[index - 1] = selectedFields[index];
      selectedFields[index] = obj
      this.setState({ selectedFields })
    }
  };

  updateListState = (value, node, list) => {
    this.setState(prevState => ({
      [list]: {
        ...prevState[list],
        [node]: value
      }
    }))
  }

  handleInlineEdits = (id, dataItem) => {
    let allDetails = [...this.state.renderList];
    this.setState({
      renderList: allDetails.map((item) => {
        let newItem = { ...item }
        if (item[this.state.config.primaryKey] == id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };
  saveInlineEdits = (data, index, id, dataItem) => {
    let allDetails = [...this.state.renderList];
    this.setState({
      renderList: allDetails.map((item) => {
        if (item[this.state.config.primaryKey] == id) item["editing"] = false;
        return item;
      }),
    });
  };

  handleSubmit = () => {
    this.setState({ postInitiated: true });
    let { adhocDetails, filterValues, seasonIdValues, showIdValues, selectedFields, selectedGroupFields, filterEpTitleValues, filterEntityValues, filterShowValues, filterSeasonValues, filterEpisodeValues, filterEpisodeRangeValues, filterShowYearValues, filterCompositionValues, filterPerformerValues, filterLicensorValues, filterLicensorTypeValues, filterInitialRightsValues, filterOptionalRightValues, filterTerritoryValues, filterTermValues, filterUseTimingValues, filterStatusValues, filterClearanceRepValues } = this.state;
    let sortByFields = selectedFields.map(item => item.label)
    let groupByFields = selectedGroupFields.map(item => item.label)
    let airDateValue = filterValues.findIndex((item) => item.split(":")[0] === "Air Date");
    let value, startDate = null, endDate = null;
    if (airDateValue != -1) {
      value = filterValues[airDateValue].split(":")[1];
      startDate = value.split("To")[0].trim();
      endDate = value.split("To")[1].trim();
    }
    let feeRange = filterValues.findIndex((item) => item.split(":")[0] === "Fee");
    let feevalue, startFee = null, endFee = null;
    if (feeRange != -1) {
      value = filterValues[feeRange].split(":")[1];
      startFee = value.split("To")[0].trim();
      endFee = value.split("To")[1].trim();
    }
    let episodeRange = filterValues.findIndex((item) => item.split(":")[0] === "Episode Range");
    let epValue, epFrom = null, epTo = null;
    if (episodeRange != -1) {
      value = filterValues[episodeRange].split(":")[1];
      epFrom = value.split("-")[0].trim();
      epTo = value.split("-")[1].trim();
    }
    let payload =
    {
      prior_season_search_flag: adhocDetails.prior_data_flag,
      show_search_ids: filterShowValues.length ? filterShowValues?.join() : null,
      season_search_ids: filterSeasonValues.length ? filterSeasonValues?.join() : null,
      show_year_search_text: filterShowYearValues.length ? filterShowYearValues?.join() : null,
      episode_title_search_text: filterEpTitleValues.length ? filterEpTitleValues.join() : null,
      episode_no_search_ids: filterEpisodeValues.length ? filterEpisodeValues?.join() : null, //changed
      episode_r1_search_text: epFrom,
      episode_r2_search_text: epTo,
      composition_search_text: filterCompositionValues.length ? filterCompositionValues?.join() : null,
      writer_search_ids: null,//changed
      production_entity_search_ids: filterEntityValues.length ? filterEntityValues?.join() : null,
      clearance_rep_search_ids: filterClearanceRepValues.length ? filterClearanceRepValues?.join() : null,
      licensor_search_text: filterLicensorValues.length ? filterLicensorValues?.join() : null,
      licensor_type_search_ids: filterLicensorTypeValues.length ? filterLicensorTypeValues?.join() : null,
      artist_search_text: filterPerformerValues.length ? filterPerformerValues?.join() : null, //performer?
      use_search_text: filterUseTimingValues.length ? filterUseTimingValues?.join() : null,
      init_right_search_ids: filterInitialRightsValues.length ? filterInitialRightsValues?.join() : null,
      option_right_search_ids: filterOptionalRightValues.length ? filterOptionalRightValues?.join() : null,
      term_search_ids: filterTermValues.length ? filterTermValues?.join() : null,
      territory_search_ids: filterTerritoryValues.length ? filterTerritoryValues?.join() : null,
      status_search_ids: filterStatusValues.length ? filterStatusValues?.join() : null,
      order_by_clause: sortByFields.length ? sortByFields?.join() : null,
      group_by_clause: groupByFields.length ? groupByFields?.join() : null,
      order_by: null,
      show_season_episode_search_comparison_op: adhocDetails.progSeasonEpisode_Equals,
      show_year_search_comparison_op: adhocDetails.showYear_Equals,
      episode_title_search_comparison_op: adhocDetails.progSeasonEpisode_Equals,
      composition_search_comparison_op: adhocDetails.composition_Equals,
      writer_search_comparison_op: adhocDetails.writer_Equals,
      artist_search_comparison_op: adhocDetails.performer_Equals,
      production_entity_search_comparison_op: adhocDetails.prodEntity_Equals,
      clearance_rep_search_comparison_op: adhocDetails.clearanceRep_Equals,
      licensor_search_comparison_op: adhocDetails.licensor_Equals,
      licensor_type_search_comparison_op: adhocDetails.licensorType_Equals,
      use_search_comparison_op: adhocDetails.useTiming_Equals,
      init_right_search_comparison_op: adhocDetails.initRights_Equals,
      option_right_search_comparison_op: adhocDetails.optRights_Equals,
      term_search_comparison_op: adhocDetails.term_Equals,
      territory_search_comparison_op: adhocDetails.territory_Equals,
      status_search_comparison_op: adhocDetails.status_Equals,
      air_date_start: startDate,
      air_date_end: endDate,
      fee_range_start: startFee,
      fee_range_end: endFee,
      batch: adhocDetails?.Batch
    }
    this.props.generateReport("rptAdhocReport", payload, "Adhoc").then(webSocketConn => {
      webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data)
        if (response?.statusCode == 200) {
          this.setState({ isPosting: false })
          this.props?.downloadUrl(response?.body);
          this.setState({ postInitiated: false });
          // this.props?.notificationComponent(true, 'success')
        } else {
          response?.statusCode && this.setState({ postInitiated: false });
          // this.props?.notificationComponent(true, 'fail')
        }
      }
    })

  };
  handleDelete = () => { };
  handleClick = () => { };
  GenerateAdhocDetails = () => {
    let selectedFields = [...this.state.selectedFields];
    let newValue = this.state.selectedType
    if (JSON.stringify(newValue) !== '{}') {
      selectedFields.push(newValue)
    }
    let sortFieldOptions = this.state.sortFieldOptions
    let filteredSortOptions = sortFieldOptions.filter(item => item.value !== newValue?.value)
    this.setState({ selectedFields: selectedFields, selectedType: {}, sortFieldOptions: filteredSortOptions })
  };

  GenerateAdhocGroupByDetails = () => {
    let selectedGroupFields = [...this.state.selectedGroupFields];
    let newValue = this.state.selectedGroupType
    if (JSON.stringify(newValue) !== '{}') {
      selectedGroupFields.push(newValue)
    }
    let groupByOptions = this.state.groupByOptions
    let filteredGroupOptions = groupByOptions.filter(item => item.value !== newValue?.value)
    this.setState({ selectedGroupFields: selectedGroupFields, selectedGroupType: {}, groupByOptions: filteredGroupOptions })
  };

  handleUpdateExistingRecord = (id) => {
    this.setState({ selectedId: id })
  }

  onTextChange = (field, val) => {
    let filterValues = this.state.filterValues;
    if (field === 'Composition') {
      filterValues.push("Composition " + (this.state.adhocDetails?.composition_Equals === "LIKE" ? "LIKE" : "NOTLIKE") + ": " + val)
      let filterCompositionValues = this.state.filterCompositionValues
      filterCompositionValues.push(val)
      this.setState({ filterCompositionValues }, () => {
        this.setState((prevState) => ({
          adhocDetails: {
            ...prevState.adhocDetails,
            ['composition']: "",
          },
        }))
      })
    } else if (field === 'Licensor') {
      filterValues.push("Licensor " + (this.state.adhocDetails?.licensor_Equals === "LIKE" ? "LIKE" : "NOTLIKE") + ": " + val)
      let filterLicensorValues = this.state.filterLicensorValues
      filterLicensorValues.push(val)
      this.setState({ filterLicensorValues }, () => {
        this.setState((prevState) => ({
          adhocDetails: {
            ...prevState.adhocDetails,
            ['licensor']: "",
          },
        }))
      })
    } else if (field === 'UseTiming') {
      filterValues.push("Use Timing " + (this.state.adhocDetails?.useTiming_Equals === "LIKE" ? "LIKE" : "NOTLIKE") + ": " + val)
      let filterUseTimingValues = this.state.filterUseTimingValues
      filterUseTimingValues.push(val)
      this.setState({ filterUseTimingValues }, () => {
        this.setState((prevState) => ({
          adhocDetails: {
            ...prevState.adhocDetails,
            ['useTiming']: "",
          },
        }))
      })
    } else if (field === 'Episode Range') {
      if (filterValues.length > 0) {
        let presentInd = filterValues.findIndex((item) => item.split(":")[0] === field);
        if (presentInd != -1) {
          filterValues[presentInd] = ("Episode Range" + ": " + this.state.adhocDetails?.episode_from + " - " + this.state.adhocDetails?.episode_from);
        } else {
          filterValues.push("Episode Range" + ": " + this.state.adhocDetails?.episode_from + "- " + this.state.adhocDetails?.episode_to)
        }
      }
      else {
        filterValues.push("Episode Range" + ": " + this.state.adhocDetails?.episode_from + "- " + this.state.adhocDetails?.episode_to)
      }
      this.setState((prevState) => ({
        adhocDetails: {
          ...prevState.adhocDetails,
          ['episode_from']: "",
          ['episode_to']: ""
        },
      }))
    } else if (field === 'Episode Title') {
      filterValues.push("Episode Title " + (this.state.adhocDetails?.progSeasonEpisode_Equals === "EQUAL" ? "LIKE" : "NOTLIKE") + ": " + val)
      let filterEpTitleValues = this.state.filterEpTitleValues
      filterEpTitleValues.push(val)
      this.setState({ filterEpTitleValues }, () => {
        this.setState((prevState) => ({
          adhocDetails: {
            ...prevState.adhocDetails,
            ['episode_title']: "",
          },
        }))
      })
    } else if (field === 'Air Date') {
      if (filterValues.length > 0) {
        let presentInd = filterValues.findIndex((item) => item.split(":")[0] === field);
        if (presentInd != -1) {
          filterValues[presentInd] = ("Air Date" + ": " + this.state.adhocDetails?.airDateStart + " To " + this.state.adhocDetails?.airDateEnd);
        } else {
          filterValues.push("Air Date" + ": " + this.state.adhocDetails?.airDateStart + " To " + this.state.adhocDetails?.airDateEnd)
        }
      }
      else {
        filterValues.push("Air Date" + ": " + this.state.adhocDetails?.airDateStart + " To " + this.state.adhocDetails?.airDateEnd)
      }
      this.setState((prevState) => ({
        adhocDetails: {
          ...prevState.adhocDetails,
          ['airDateStart']: "",
          ['airDateEnd']: ""
        },
      }))
    } else if (field === 'Fee') {
      if (filterValues.length > 0) {
        let presentInd = filterValues.findIndex((item) => item.split(":")[0] === field);
        if (presentInd != -1) {
          filterValues[presentInd] = ("Fee" + ": " + this.state.adhocDetails?.feeStart + " To " + this.state.adhocDetails?.feeEnd);
        } else {
          filterValues.push("Fee" + ": " + this.state.adhocDetails?.feeStart + " To " + this.state.adhocDetails?.feeEnd)
        }
      }
      else {
        filterValues.push("Fee" + ": " + this.state.adhocDetails?.feeStart + " To " + this.state.adhocDetails?.feeEnd)
      }
      this.setState((prevState) => ({
        adhocDetails: {
          ...prevState.adhocDetails,
          ['feeStart']: "",
          ['feeEnd']: ""
        },
      }))
    }
    this.setState({ filterValues })
  }
  onSelectChange = (val, field) => {
    let filterValues = this.state.filterValues;
    if (field === 'Production Entity' && !this.state.filterEntityValues?.includes(val?.target.value)) {
      filterValues.push("Production Entity " + (this.state.adhocDetails?.prodEntity_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterEntityValues = this.state.filterEntityValues
      filterEntityValues.push(val?.target.value)
      this.setState({ filterEntityValues })
    } else if (field === 'Writer' && !this.state.filterWriterIdValues?.includes(val?.target.value)) {
      filterValues.push("Writer " + (this.state.adhocDetails?.writer_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterWriterIdValues = this.state.filterWriterIdValues
      filterWriterIdValues.push(val?.target.value)
      this.setState({ filterWriterIdValues})
    } else if (field === 'Show Year' && !this.state.filterShowYearValues?.includes(val?.target.value)) {
      filterValues.push("Show Year " + (this.state.adhocDetails?.showYear_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterShowYearValues = this.state.filterShowYearValues
      let filteredShowYearOptions = showYearOptions.filter(item => item.value !== val?.target.value)
      this.setState({ filterShowYearValues })
    } else if (field === 'Performer' && !this.state.filterPerformerValues?.includes(val?.target.value)) {
      filterValues.push("Performer " + (this.state.adhocDetails?.performer_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterPerformerValues = this.state.filterPerformerValues
      filterPerformerValues.push(val?.target.value)
      this.setState({ filterPerformerValues})
    } else if (field === 'Licensor Type' && !this.state.filterLicensorTypeValues?.includes(val?.target.value)) {
      filterValues.push("Licensor Type " + (this.state.adhocDetails?.licensorType_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterLicensorTypeValues = this.state.filterLicensorTypeValues
      filterLicensorTypeValues.push(val?.target.value)
      this.setState({ filterLicensorTypeValues})
    } else if (field === 'Initial Rights'  && !this.state.filterInitialRightsValues?.includes(val?.target.value)) {
      filterValues.push("Initial Rights " + (this.state.adhocDetails?.initRights_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterInitialRightsValues = this.state.filterInitialRightsValues
      filterInitialRightsValues.push(val?.target.value)
      this.setState({ filterInitialRightsValues})
    } else if (field === 'Optional Rights' && !this.state.filterOptionalRightValues?.includes(val?.target.value)) {
      filterValues.push("Optional Rights " + (this.state.adhocDetails?.optRights_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterOptionalRightValues = this.state.filterOptionalRightValues
      filterOptionalRightValues.push(val?.target.value)
      this.setState({ filterOptionalRightValues })
    } else if (field === 'Territory' && !this.state.filterTerritoryValues?.includes(val?.target.value)) {
      filterValues.push("Territory " + (this.state.adhocDetails?.territory_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterTerritoryValues = this.state.filterTerritoryValues
      filterTerritoryValues.push(val?.target.value)
      this.setState({ filterTerritoryValues })
    } else if (field === 'Term'  && !this.state.filterTermValues?.includes(val?.target.value)) {
      filterValues.push("Term " + (this.state.adhocDetails?.term_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterTermValues = this.state.filterTermValues
      filterTermValues.push(val?.target.value)
      this.setState({ filterTermValues})
    } else if (field === 'Status' && !this.state.filterStatusValues?.includes(val?.target.value)) {
      filterValues.push("Status " + (this.state.adhocDetails?.status_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterStatusValues = this.state.filterStatusValues
      filterStatusValues.push(val?.target.value)
      this.setState({ filterStatusValues})
    } else if (field === 'Clearance Rep' && !this.state.filterClearanceRepValues?.includes(val?.target.value)) {
      filterValues.push("Clearance Rep " + (this.state.adhocDetails?.clearanceRep_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterClearanceRepValues = this.state.filterClearanceRepValues
      filterClearanceRepValues.push(val?.target.value)
      this.setState({ filterClearanceRepValues})
    } else if (field === 'Show' && !this.state.filterShowValues?.includes(val?.target.value)) {
      filterValues.push("Show " + (this.state.adhocDetails?.progSeasonEpisode_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterShowValues = [...this.state.filterShowValues]
      filterShowValues.push(val?.target.value)
      this.setState({ filterShowValues }, () => {
        this.filterSeasons(filterShowValues.join())
      })
    } else if (field === 'Season' && !this.state.filterSeasonValues?.find(item => item === val?.target?.value)) {
      filterValues.push("Season " + (this.state.adhocDetails?.progSeasonEpisode_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterSeasonValues = [...this.state.filterSeasonValues]
      filterSeasonValues.push(val?.target.value)
      let filteredShowSeasonIds = this.state.filteredShowSeasonIds
      if (this.state.filterShowSeasonsIds.length > 0) {
        let showSeasonId = this.state.backUpSeasonOptions.filter(item => item.value === val?.target.value)
        if (showSeasonId.length > 0) {
          for (let i = 0; i < showSeasonId.length; i++) {
            filteredShowSeasonIds.push({ showSeasonId: showSeasonId[i]?.show_season_id, seasonId: val?.target.value })
          }
        }
      }
      this.setState({ filterSeasonValues, filteredShowSeasonIds }, () => {
        if (filteredShowSeasonIds?.length > 0) {
          let fetchedShowSeasonIds = filteredShowSeasonIds.map(item => item.showSeasonId)
          this.filterEpisodes(fetchedShowSeasonIds.join())
        }
      })
    } else if (field === 'Episode' && !this.state.filterEpisodeValues?.find(item => item === val?.target?.value)) {
      filterValues.push("Episode " + (this.state.adhocDetails?.progSeasonEpisode_Equals == "EQUAL" ? "EQ" : "NOTEQ") + ": " + val?.currentTarget.innerText)
      let filterEpisodeValues = this.state.filterEpisodeValues
      filterEpisodeValues.push(val?.target.value)
      this.setState({ filterEpisodeValues })
    }     
    else if (field === 'Batch' && val?.target.value ) {    
        this.setState((prevState) => ({
          adhocDetails: {
            ...prevState.adhocDetails,
            [field]: val?.target.value,
          },
        }),()=>{
          let presentInd = filterValues.findIndex((item) => item.split(":")[0] === field);
          if (presentInd != -1) {
               filterValues[presentInd] = ("Batch" + ": " +  val?.target.value);
            } else {
              filterValues.push("Batch" + ": " +  val?.target.value);
            }
            this.setState({ filterValues });
        })
    } 
    this.setState({ filterValues })
  }

  selectedTabComponent = () => {
    switch (this.state.selectedId) {
      case 1:
        return this.progSeasonEpisode();
      case 2:
        return this.episodeRange();
      case 3:
        return this.showYear();
      case 4:
        return this.composition();
      case 5:
        return this.writer();
      case 6:
        return this.performer();
      case 7:
        return this.prodEntity();
      case 8:
        return this.licensor();
      case 9:
        return this.licensorType();
      case 10:
        return this.initialRights();
      case 11:
        return this.optionRights();
      case 12:
        return this.territory();
      case 13:
        return this.term();
      case 14:
        return this.useTiming();
      case 15:
        return this.status();
      case 16:
        return this.clearanceRep();
      case 17:
        return this.airDate();
      case 18:
        return this.FeeRange();
      case 19:
        return this.batchSelection();
    }
  };

  episodeRange = () => {
    return (
      <div>
        <br />
        <MDBRow>
          <MDBCol sm={2}>
            <BasicTextField
              //  label={"Performer"}
              //  isMandatory={true}
              //  showMandatory={this.state.postInitiated || false}
              value={this.state.adhocDetails?.episode_from || ""}
              placeholder={'-episode from-'}
              onChange={(e) => validateNumbersOnly(e.target.value) ? this.handleChange('episode_from', e.target.value > 0 ? e.target.value : null) : null}
            />
          </MDBCol>
          <MDBCol sm={2}>
            <span> To </span>
          </MDBCol>
          <MDBCol sm={2}>
            <BasicTextField
              value={this.state.adhocDetails?.episode_to || ""}
              placeholder={'-episode to-'}
              onChange={(e) => validateNumbersOnly(e.target.value) ? this.handleChange('episode_to', e.target.value > 0 ? e.target.value : null) : null}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <BasicButton text={"Apply"} onClick={() => this.onTextChange('Episode Range', null)} />
        </MDBRow>
      </div>
    )
  }

  useTiming = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.useTiming_Equals}
            onChange={(e) => this.handleChange("useTiming_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="LIKE"
              control={<Radio size="small" />}
              label="Like"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_LIKE"
              control={<Radio size="small" />}
              label="Not Like"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <BasicTextField
            label={"Use / Timing"}
            //  isMandatory={true}
            //  showMandatory={this.state.postInitiated || false}
            placeholder={'-type and hit enter-'}
            value={this.state.adhocDetails?.useTiming || ""}
            onChange={(e) => this.handleChange('useTiming', e.target.value)}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                this.onTextChange('UseTiming', event.target.value)
              }
            }}
          />
        </MDBRow>
      </div>
    )
  }

  writer = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.writer_Equals}
            onChange={(e) => this.handleChange("writer_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.writerOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Writer')}
            label={"Writer"}
          />
        </MDBRow>
      </div>
    )
  }

  performer = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.performer_Equals}
            onChange={(e) => this.handleChange("performer_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow>
          <MDBCol
            md={12}
            id="initialRights" >
            <SearchSelectField
              label={"Performer"}
              width={"100%"}
              id={"performer"}
              multiple={true}
              placeholder="- Search and Select -"
              value={[]}s
              options={this.state.performerOptions || []}
              onChange={(e, newValue) => {
                this.onChangeCallBack(
                  "performerList",
                  newValue,
                  e.target.value
                );
              }}
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }

  territory = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.territory_Equals}
            onChange={(e) => this.handleChange("territory_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.territoryOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Territory')}
            label={"Territory"}
          />
        </MDBRow>
      </div>
    )
  }

  clearanceRep = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.clearanceRep_Equals}
            onChange={(e) => this.handleChange("clearanceRep_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.clearanceRepOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Clearance Rep')}
            label={"Clearance Rep"}
          />
        </MDBRow>
      </div>
    )
  }

  airDate = () => {
    return (
      <div>
        <br />
        <MDBRow>
          <MDBCol md={5}>
            <DateField
              id="acdhocReportAirDateStart"
              value={getFormattedDate(this.state.adhocDetails?.airDateStart, true) || null}
              onChange={(e) => this.handleChange('airDateStart', e.target.value)}
            />
          </MDBCol>
          <MDBCol sm={2}>
            <span> To </span>
          </MDBCol>
          <MDBCol md={5}>
            <DateField
              id="acdhocReportAirDateEnd"
              value={getFormattedDate(this.state.adhocDetails?.airDateEnd, true) || null}
              onChange={(e) => this.handleChange('airDateEnd', e.target.value)}
              showErrorBorder={this.state.adhocDetails?.airDateStart > this.state.adhocDetails?.airDateEnd ? true : false}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <BasicButton text={"Apply"} onClick={() => this.onTextChange('Air Date', null)} />
        </MDBRow>
      </div>
    )
  }
  batchSelection = ()=>{
    return (
      <div>
        <MDBRow>
          <MDBCol md={5}>
          <SelectField
            value={this.state.adhocDetails?.Batch}
            options={this.state.batchOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Batch')}
            label={"Batch"}
          />
          </MDBCol>
         
        </MDBRow>
      </div>
    )
  }
  FeeRange = () => {
    return (
      <div>
        <br />
        <label style={{ fontSize: 'small', paddingBottom: '10px' }}>Fee Range</label>
        <MDBRow>
          <MDBCol md={5}>
              <BasicTextField
                id="acdhocReportFeeStart"
                value={this.state.adhocDetails?.feeStart || null}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  if (inputValue.match(/^[0-9]*$/)) {
                    this.handleChange('feeStart', inputValue);
                  }
                }}
                prefix={"$"}
                placeholder={'$ Amount'}
              />
          </MDBCol>
          <MDBCol sm={2}>
            <span> To </span>
          </MDBCol>
          <MDBCol md={5}>
            
            <BasicTextField
              id="acdhocReportFeeEnd"
              value={ this.state.adhocDetails?.feeEnd || null }
              onChange={(e) => {
                const inputValue = e.target.value;

                if (inputValue.match(/^[0-9]*$/)) {
                  this.handleChange('feeEnd', inputValue);
                }
              }}
              showErrorBorder={this.state.adhocDetails?.feeStart > this.state.adhocDetails?.feeEnd ? true : false}
              prefix={"$"}
              placeholder={'$ Amount'}
              />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <BasicButton text={"Apply"} onClick={() => this.onTextChange('Fee', null)} />
        </MDBRow>
      </div>
    )
  }

  status = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.status_Equals}
            onChange={(e) => this.handleChange("status_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.statusOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Status')}
            label={"Status"}
          />
        </MDBRow>
      </div>
    )
  }

  term = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.term_Equals}
            onChange={(e) => this.handleChange("term_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.termOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Term')}
            label={"Term"}
          />
        </MDBRow>
      </div>
    )
  }

  prodEntity = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.prodEntity_Equals}
            onChange={(e) => this.handleChange("prodEntity_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.productionEntityOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Production Entity')}
            label={"Production Entity"}
          />
        </MDBRow>
      </div>
    )
  }

  initialRights = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.initRights_Equals}
            onChange={(e) => this.handleChange("initRights_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.initialRightOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Initial Rights')}
            label={"Initial Rights"}
          />
        </MDBRow>
      </div>
    )
  }

  optionRights = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.optRights_Equals}
            onChange={(e) => this.handleChange("optRights_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.optionalRightOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Optional Rights')}
            label={"Optional Rights"}
          />
        </MDBRow>
      </div>
    )
  }

  composition = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.composition_Equals}
            onChange={(e) => this.handleChange("composition_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="LIKE"
              control={<Radio size="small" />}
              label="Like"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_LIKE"
              control={<Radio size="small" />}
              label="Not Like"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <BasicTextField
            label={"Composition"}
            placeholder={'-type and hit enter-'}
            value={this.state.adhocDetails?.composition || ""}
            onChange={(e) => this.handleChange('composition', e.target.value)}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                this.onTextChange('Composition', event.target.value)
              }
            }}
          />
        </MDBRow>
      </div>
    )
  }

  showYear = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.showYear_Equals}
            onChange={(e) => this.handleChange("showYear_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.showYearOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Show Year')}
            label={"Show Year"}
          />
        </MDBRow>
      </div>
    )
  }

  licensorType = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.licensorType_Equals}
            onChange={(e) => this.handleChange("licensorType_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.licensorTypeOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Licensor Type')}
            label={"Licensor Type"}
          />
        </MDBRow>
      </div>
    )
  }

  licensor = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.licensor_Equals}
            onChange={(e) => this.handleChange("licensor_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="LIKE"
              control={<Radio size="small" />}
              label="Like"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_LIKE"
              control={<Radio size="small" />}
              label="Not Like"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow className="margin-right">
          <BasicTextField
            label={"Licensor"}
            value={this.state.adhocDetails?.licensor || ""}
            placeholder={'-type and hit enter-'}
            onChange={(e) => this.handleChange('licensor', e.target.value)}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                this.onTextChange('Licensor', event.target.value)
              }
            }}
          />
        </MDBRow>
      </div>
    )
  }

  progSeasonEpisode = () => {
    return (
      <div>
        <MDBRow>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radio-group-height"
            value={this.state.adhocDetails?.progSeasonEpisode_Equals}
            onChange={(e) => this.handleChange("progSeasonEpisode_Equals", e.target.value)}
          >
            <FormControlLabel
              className="LabelSize"
              value="EQUAL"
              control={<Radio size="small" />}
              label="Equal"
            />
            <FormControlLabel
              className="LabelSize"
              value="NOT_EQUAL"
              control={<Radio size="small" />}
              label="Not Equal"
            />
          </RadioGroup>
        </MDBRow>
        <MDBRow>
          <MDBCol
            md={12}
            id="initialRights" >
            <SearchSelectField
              label={"Show"}
              width={"100%"}
              id={"showSelect"}
              multiple={true}
              placeholder="- Search and Select -"
              value={[]}
              options={this.state.showOptions || []}
              onChange={(e, newValue) => {
                this.onChangeCallBack(
                  "showList",
                  newValue,
                  e.target.value
                );
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.seasonOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Season')}
            label={"Season"}
          />
        </MDBRow>
        <MDBRow className="margin-right">
          <SelectField
            options={this.state.episodeOptions || []}
            placeHolderText="-Select-"
            onChange={(e) => this.onSelectChange(e, 'Episode')}
            label={"Episode"}
          />
        </MDBRow>
        <MDBRow className="margin-right">
          <BasicTextField
            label={"Episode Title"}
            value={this.state.adhocDetails?.episode_title || ""}
            placeholder={'-type and hit enter-'}
            onChange={(e) => this.handleChange('episode_title', e.target.value)}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                this.onTextChange('Episode Title', event.target.value)
              }
            }}
          />
        </MDBRow>
      </div>
    );
  };


  render() {
    let length = this.state.selectedFields?.length;
    let groupLength = this.state.selectedGroupFields?.length;
    return (
      <>
        {" "}
        <h5>Ad Hoc Report</h5>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
                />
              ))}
          </div>
        </MDBRow>
        <MDBRow>
          <MDBCol md={7}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <div className="adhocContainer">
                    <MDBRow>
                      <MDBCol>
                        <div className="existing-records">
                          {
                            // this.state.isExistCostCenterLoading ? <div style={{ textAlign: "center" }} ><CircularProgress size={24} /></div> : 
                            this.state.existingAdHocReport?.map((item, index) => (
                              <div key={index} className="cost-center-item" onClick={() => this.handleUpdateExistingRecord(item.value)}>
                                <span style={{ color: this.state.selectedId === item.value ? "#164f9f" : "", fontSize: "0.8rem" }}
                                > {item.label ? `${[item.label]}` : null}</span>
                              </div>
                            ))}
                        </div>
                      </MDBCol>
                      <MDBCol>
                        {this.selectedTabComponent()}
                      </MDBCol>
                    </MDBRow>
                  </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md={5}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <div>

                    <MDBCol>
                      <MDBRow>
                        <h6>
                          <b>Options</b>
                        </h6>
                      </MDBRow>
                      <MDBRow>
                        <BasicCheckbox
                          id={"is_include_data"}
                          label="Include Data Prior to 2011-2012 Season"
                          className="default-checkbox"
                          checked={this.state.adhocDetails?.prior_data_flag}
                          onChange={(e) =>
                            this.handleChange(
                              "prior_data_flag",
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                      </MDBRow>
                      <br />
                      <MDBRow>
                        <h6>
                          <b>Sorting and Grouping</b>
                        </h6>
                        <br />
                        <MDBCol md={6}>
                          <p> Sort Order</p>
                          {this.state.selectedFields?.map((item, index) => (
                            <MDBRow>
                              <MDBCol md={8}>
                                <BasicLabel text={item?.label} />
                              </MDBCol>
                              <MDBCol md={1}>
                                {index !== length - 1 ?
                                  <MDBIcon
                                    icon="arrow-down"
                                    onClick={() => this.sortingGroupList(index, 'down')}
                                  /> : null}
                              </MDBCol>
                              <MDBCol md={1}>
                                {index !== 0 ?
                                  <MDBIcon
                                    icon="arrow-up"
                                    onClick={() => this.sortingGroupList(index, 'up')}
                                  /> : null}
                              </MDBCol>
                              <MDBCol md={1}>
                                <MDBIcon icon="times"
                                  onClick={() => this.removeSortFields(index, 'sort')} />
                              </MDBCol>
                            </MDBRow>
                          ))}
                          <MDBRow>
                            <MDBCol md={8}>
                              <SelectField
                                placeHolderText={"- Select Available Field -"}
                                value={this.state.selectedType?.value}
                                options={this.state.sortFieldOptions}
                                onChange={(e) => this.handleChangeDropDown('selectedType', e)}
                              />
                            </MDBCol>
                            <MDBCol md={2}></MDBCol>
                            <MDBCol md={1}>
                              <MDBIcon icon="plus-circle" onClick={this.GenerateAdhocDetails} />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>

                        <br />
                        <MDBCol md={6}>
                          <p> Subtotal Grouping </p>
                          {this.state.selectedGroupFields?.map((item, index) => (
                            <MDBRow>
                              <MDBCol md={8}>
                                <BasicLabel text={item?.label} />
                              </MDBCol>
                              <MDBCol md={1}>
                                {index !== groupLength - 1 ?
                                  <MDBIcon icon="arrow-down" onClick={() => this.sortingGroupByList(index, 'down')} />
                                  : null}
                              </MDBCol>
                              <MDBCol md={1}>
                                {index !== 0 ?
                                  <MDBIcon icon="arrow-up" onClick={() => this.sortingGroupByList(index, 'up')} />
                                  : null}
                              </MDBCol>
                              <MDBCol md={1}>
                                <MDBIcon icon="times" onClick={() => this.removeSortFields(index, 'group')} />
                              </MDBCol>
                              <MDBCol md={1}></MDBCol>
                            </MDBRow>
                          ))}
                          <MDBRow>
                            <MDBCol md={8}>
                              <SelectField
                                placeHolderText={"- Select Available Field -"}
                                value={this.state.selectedGroupType?.value}
                                options={this.state.groupByOptions}
                                onChange={(e) => this.handleChangeDropDown('selectedGroupType', e)}
                              />
                            </MDBCol>
                            <MDBCol md={2}></MDBCol>
                            <MDBCol md={1}>
                              <MDBIcon icon="plus-circle" onClick={this.GenerateAdhocGroupByDetails} />
                            </MDBCol>
                            <MDBCol md={1}></MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
        </MDBRow>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genexl-btn">
                <br />
                <BasicButton
                  className="genBtn"
                  onClick={this.handleSubmit}
                  text={this.state.postInitiated ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : "Generate Excel"}
                />
              </MDBCol>
              {/* <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={"Generate PDF"} />
              </MDBCol> */}
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} onClick={this.handleClearFilters} />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <br />
      </>
    );
  }
}

export default withUserContext(AdHocReport);
