import React from 'react';
import { withUserContext } from '../../../contexts/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import TableComponent from '../../SharedComponents/Table';
import { cueSheetRecurringCueConfig, initialRecurringCueConfig } from '../../EpisodeAndClearence/EpisodeAndClearenceDetails/Config';
import { handlePaginationList } from '../../../Common/TableHelper';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import {validateNumbersOnly} from '../../../Common/Helper'



export default withUserContext(class RecurringCue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingReccuringCues: false,
            renderList: [],
            config: JSON.parse(JSON.stringify({ ...cueSheetRecurringCueConfig })),
            recurringCueDetails: JSON.parse(JSON.stringify({ ...initialRecurringCueConfig })),
            page: 0,
            rowsPerPage: 10,
            isSubmit: false,
            isErrorFlag: false,
            nodeName: null,
            showPublisherModal: false,
            showComposerModal: false,
            OpenNotification: null,
            isDurationValid: false
        }
    };

    componentDidMount() {
        this.getRecurringCues();
        this.getEntity('musicUses', 'MUSIC_USES', '');
    }

    getRecurringCues = (loader = true) => {
        this.setState({ isFetchingReccuringCues: loader })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonRecurringCues?show_season_id=${this.props?.showSeasonId}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let cueSheetRecurringCueConfig = { ...this.state.config }
                cueSheetRecurringCueConfig.newRowSelectOptions[1].addRowOptions = [...this.props?.composerOptions]
                let responseData = response?.data[0]
                if(responseData?.show_season_recurring_cue_list?.length > 0){
                    for(let i = 0 ; i < responseData?.show_season_recurring_cue_list?.length ; i++) {
                        responseData.show_season_recurring_cue_list[i].min = responseData?.show_season_recurring_cue_list[i].duration  ? responseData?.show_season_recurring_cue_list[i].duration?.split(':')[0] : null
                        responseData.show_season_recurring_cue_list[i].sec =  responseData?.show_season_recurring_cue_list[i].duration  ? responseData?.show_season_recurring_cue_list[i].duration?.split(':')[1] : null
                        responseData.show_season_recurring_cue_list[i].colon =  ':'
                        responseData.show_season_recurring_cue_list[i].editing = false
                        delete responseData?.show_season_recurring_cue_list[i]?.duration 
                    }
                } 
                this.setState({
                    showSeasonRecurringCues: responseData,
                    page: 0,
                    renderList: handlePaginationList(0, this.state.rowsPerPage, responseData?.show_season_recurring_cue_list),
                    isFetchingReccuringCues: false, isSubmit: false,
                    config: cueSheetRecurringCueConfig
                })
            })
    }


    getEntity = (entity, entityType, searchString) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let cueSheetRecurringCueConfig = { ...this.state.config }
                if (entityType === "MUSIC_USES") {
                    let musicUses = response?.data?.map(item => ({
                        label: item.abbreviation,
                        value: item.music_uses_id,
                    }));
                    cueSheetRecurringCueConfig.headings[3].selectOptions = musicUses
                    this.setState({ [entity]: musicUses, config: cueSheetRecurringCueConfig })
                }
            })
    }

    formatRecurringCue = (dataItem, mode = null) => {
        this.setState({ mode: mode })
        let recurringCueDetails = { ...this.state.recurringCueDetails }
        recurringCueDetails.show_season_id = this.props?.showSeasonId || null
        recurringCueDetails.show_id = this.props?.showId || null;
        recurringCueDetails.season_id = this.props?.seasonId || null;
        recurringCueDetails.composer = dataItem?.composer || null;
        recurringCueDetails.publisher = dataItem?.publisher || null;
        recurringCueDetails.min = dataItem?.min || null;
        recurringCueDetails.sec = dataItem?.sec || null;
        recurringCueDetails.how_used = dataItem?.how_used || null;
        recurringCueDetails.composition = dataItem?.composition || null;
        recurringCueDetails.show_season_recurring_cue_id = dataItem?.show_season_recurring_cue_id ? dataItem?.show_season_recurring_cue_id : null;
        if (mode === "delete") {
            recurringCueDetails.is_delete = 1;
        }
        return recurringCueDetails;
    }

    postRecurringCue = (payload, postFlag) => {
        this.setState({ isSubmit: postFlag })
        payload.duration = `${payload?.min ? payload.min : 0}:${payload.sec ? payload.sec : 0}`
        delete payload.min
        delete payload.sec
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonRecurringCues`, payload, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                if (response) {
                    if (response.data.error) {
                        this.setState({ isSubmit: false, OpenNotification: 'error' })
                    } else {
                        this.getRecurringCues(false);
                        this.props?.toggleRefresh();
                        this.setState({ OpenNotification: 'success' })
                    }
                }
            }),
            (err) => {
                this.setState({ isSubmit: false, OpenNotification: 'error' })
            }
    }

    handleAddNewItem = (newDataItem) => {
        this.setState({ isErrorFlag: false})
        if (newDataItem?.composition && newDataItem?.composer && newDataItem?.publisher && newDataItem?.min && newDataItem?.how_used && 
            newDataItem?.sec && newDataItem?.sec < 60 && newDataItem?.sec && validateNumbersOnly(newDataItem?.sec) && validateNumbersOnly(newDataItem?.min)) {
                let payload = { ...this.formatRecurringCue(newDataItem) }
                this.postRecurringCue(payload, 'newItemPost')
        } else {
            this.setState({ isErrorFlag: true })
        }
    }

    handleInlineEdits = (id, dataItem) => {
        let recurringCueDetails = [...this.state.renderList]
        this.setState({
            renderList: recurringCueDetails.map((item) => {
                let newItem  = {...item}
                if (item.show_season_recurring_cue_id == id) {
                    newItem['editing'] = true
                } else {
                    newItem['editing'] = false
                }
                return newItem
            })
        })
    }

    saveInlineEdits = (list, index, id, dataItem) => {
        this.setState({ isErrorFlag: false, isDurationValid: false })
        let recurringCueDetails = [...list];;
        if (recurringCueDetails[index]?.composition && recurringCueDetails[index]?.composer && recurringCueDetails[index]?.publisher &&  validateNumbersOnly(recurringCueDetails[index]?.sec) && validateNumbersOnly(recurringCueDetails[index]?.min) &&
            recurringCueDetails[index]?.min && recurringCueDetails[index]?.how_used && recurringCueDetails[index]?.sec && recurringCueDetails[index]?.sec < 60 ) {
                let payload = { ...this.formatRecurringCue(recurringCueDetails[index]) };
                this.postRecurringCue(payload, recurringCueDetails[index].show_season_recurring_cue_id)
        } else {
            this.setState({ isErrorFlag: true })
        }
    }

    handleInlineDelete = (id) => {
        let recurringCueDetails = [...this.state.renderList];
        let deletedItem = recurringCueDetails?.find(item => item?.show_season_recurring_cue_id === id);
        let payload = { ...this.formatRecurringCue(deletedItem, "delete") };
        this.postRecurringCue(payload, payload.show_season_recurring_cue_id);
    }

    handleReadCallback = (index) => {
        let recurringCueDetails = [...this.state.renderList];
        recurringCueDetails[index].editing = false;
        this.setState({ renderList: recurringCueDetails, isErrorFlag: false })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.showSeasonRecurringCues?.show_season_recurring_cue_list)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
            renderList: handlePaginationList(0, event.target.value, this.state.showSeasonRecurringCues?.show_season_recurring_cue_list)
        });
    }

    handleInlineIconClick = (nodeName, index) => {
        this.setState({
            mode: !index ? "Add" : "Edit",
            nodeName: nodeName,
            cueSheetItemIndex: index
        })
        if (nodeName === "composer") {
            this.setState({ showComposerModal: true })
        } else {
            this.setState({ showPublisherModal: true })
        }
    }

    render() {
        return (
            <>
                <NotificationComponent
                    open={this.state.OpenNotification?.length > 0 ? true : false}
                    message={this.state.OpenNotification === 'success' ? 'Changes saved sucessfully' : 'Saving Changes failed'}
                    severity={this.state.OpenNotification === 'success' ? 'success' : 'error'}
                    handleResetNotify={() => this.setState({ OpenNotification: null })}
                />
                {this.state.isFetchingReccuringCues ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="cueSheet" class='row' id='mainGrid'>
                        <div className="cueSheetTable recurringCuesTableData" id="recurring-cue-data-table">
                            {this.state.isErrorFlag ? <p className="f-color">Please Enter the all Details</p> : ''}
                            {/* {this.state.isErrorFlag && !this.state.isDurationValid ? null : "Please enter duration in format =>1-3 digits:1-2 digits"} */}
                            {this.state.config?.headings[3]?.selectOptions?.length > 0 &&
                                <TableComponent
                                    list={this.state.renderList || []}
                                    className={"clearencRecord"}
                                    config={this.state.config}
                                    handleAddNewItem={this.handleAddNewItem.bind(this)}
                                    editCallback={this.handleInlineEdits.bind(this)}
                                    saveCallback={this.saveInlineEdits.bind(this)}
                                    handleDelCallBack={this.handleInlineDelete.bind(this)}
                                    readCallback={this.handleReadCallback.bind(this)}
                                    inlineIconClick={this.handleInlineIconClick.bind(this)}
                                    addItemToList={true}
                                    showClear={true}
                                    stickyHeader={true}
                                    postInitiated={this.state.isSubmit}
                                    defaultValuePopulation={ {colon : ':'}}
                                    updatedItemNodeValue={this.state.nodeName === "composer" ? this.state.composerName : this.state.publisherName}
                                />

                            }
                            <div className="Pagination">
                                <TablePagination
                                    component="div"
                                    count={this.state.showSeasonRecurringCues?.show_season_recurring_cue_list ? this.state.showSeasonRecurringCues?.show_season_recurring_cue_list?.length : 0}
                                    page={this.state.page}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    onChangePage={this.handleChangePage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                        {this.state.showComposerModal &&
                            <MainViewModalComponent
                                open={this.state.showComposerModal}
                                handleClose={() => this.setState({ showComposerModal: false })}
                                headerText={"Composer"}
                                mode={""}
                                modalName={"Add Composer"}
                                handleNodeValue={(value) => this.setState({
                                    composerName: {
                                        value: value,
                                        node: this.state.nodeName,
                                        index: this.state.cueSheetItemIndex
                                    }
                                })}
                            />
                        }
                        {this.state.showPublisherModal &&
                            <MainViewModalComponent
                                open={this.state.showPublisherModal}
                                handleClose={() => this.setState({ showPublisherModal: false })}
                                headerText={"Publisher"}
                                mode={""}
                                modalName={"Add Publisher"}
                                handleNodeValue={(value) => this.setState({
                                    publisherName: {
                                        value: value,
                                        node: this.state.nodeName,
                                        index: this.state.cueSheetItemIndex
                                    }
                                })}
                            />
                        }
                    </div>
                )
                }
            </>
        );
    }
});